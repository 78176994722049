import React, { PropTypes, Component } from 'react'
import GoogleMapReact from 'google-map-react'
import Marker from './Marker'

const lcsMapStyle = require('./lcs_map_style.json')
const darkMapStyle = require('./dark_map.json')
const lightMapStyle = require('./light_map.json')

let mapkey = 'AIzaSyCSYCwzmMpxUAXB3ptreTrV0jLpUdaNXew'
// if (process.env.NETLIFY_MAP_KEY) {
// 	mapkey = process.env.NETLIFY_MAP_KEY
// }

function createMapOptions (maps) {
  // next props are exposed at maps
  // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
  // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
  // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
  // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
  // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
  return {
    panControl: false,
    mapTypeControl: false,
    scrollwheel: false,
    styles: darkMapStyle,
  }
}

function getMarkers (marker, id) {
  return (
    <Marker
      key={id}
      lat={parseFloat(marker.lat)}
      lng={parseFloat(marker.lng)}
      name={marker.text}
      color={''}
    />
  )
}

class GoogleMap extends Component {
  static defaultProps = {
    center: {
      lat: 41.83401317282514,
      lng: -87.73196395000002,
    },
    zoom: 7,
  };

  render () {
    return (
      // Important! Always set the container height explicitly
      <section id={`layer-cake-map-locations`} className='hero is-medium is-feature-grey edge--reverse'>
        <div className='hero-body is-paddingless'>
          <div style={{ height: '50vh', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: mapkey }}
              center={this.props.mapSettings.default_center[0]}
              zoom={this.props.zoom}
              options={createMapOptions}
            >
              {this.props.mapSettings.markers.map(getMarkers)}
            </GoogleMapReact>
          </div>
        </div>
      </section>
    )
  }
}

export default GoogleMap
